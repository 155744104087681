import { createSelector } from '@reduxjs/toolkit'
import { IMarkStateContentOne } from '../../types'

const selectBase = createSelector(
	(state: RootState) => state,
	state => state.markdownSliceContentOne
)

export const oneDataDocsSelector = createSelector(
	selectBase,
	(state: IMarkStateContentOne) => state.markdownFile
)
export const oneDataDocsSelectorLoading = createSelector(
	selectBase,
	(state: IMarkStateContentOne) => state.loading
)
export const oneDataDocsSelectorError = createSelector(
	selectBase,
	(state: IMarkStateContentOne) => state.error
)
