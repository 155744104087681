import { MarkdownEditorView } from '@gravity-ui/markdown-editor'
import { Button, Modal, TextInput } from '@gravity-ui/uikit'
import { toaster } from '@gravity-ui/uikit/toaster-singleton-react-18'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchMarkdownThunk } from '../../entities/markdown/model/api/get/markdownThunk'
import { fetchCreateMarkdownThunk } from '../../entities/markdown/model/api/post/markdownThunk'

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import {
	dataDocsSelector,
	dataDocsSelectorError,
	dataDocsSelectorLoading,
} from '../../entities/markdown/model/api/get/selectors'
import { getMarkdownContentThunk } from '../../entities/markdown/model/api/getOneContent/markdownThunk'
import { fetchPutMarkdownThunk } from '../../entities/markdown/model/api/put/markdownThunk'
import { fetchDeleteMarkdownThunkSlice } from '../../entities/markdown/model/api/thunks/delete/markdownThunk'
import { useAppDispatch } from '../../shared/lib/store'

function Editor({
	onSubmit,
	editor,
	isUpdate,
}: {
	onSubmit: (value: string) => void

	editor: any
	isUpdate: boolean
}) {
	
	const [open, setOpen] = React.useState(false)
	const [titleState, setTitleState] = useState<null | string>(null)
	const [idUpdate, setIdUpdate] = useState<number | null>(null)
	const [titleUpdate, setTitleUpdate] = useState<string | null>(null)
	const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
	const dispatch = useAppDispatch()
	React.useEffect(() => {
		function submitHandler() {
			// Serialize current content to markdown markup
			const value = editor.getValue()
			onSubmit(value)
		}

		editor.on('submit', submitHandler)
		return () => {
			editor.off('submit', submitHandler)
		}
	}, [editor, onSubmit])
	const [modalList, setModalList] = useState<boolean>(false)
	const dataDocsSelectorLoadingLocal = useSelector(dataDocsSelectorLoading)
	const dataDocsSelectorLocal = useSelector(dataDocsSelector)
	const dataDocsSelectorErrorLocal = useSelector(dataDocsSelectorError)
	const handleGetData = () => {
		if (isUpdate && idUpdate && titleUpdate) {
			//idUpdate
			const markdownContent = editor.getValue()
			const markdownFile = new File(
				[markdownContent],
				`${titleUpdate || 'untitled'}.md`,
				{
					type: 'text/markdown',
				}
			)

			// Отправляем данные
			dispatch(
				fetchPutMarkdownThunk({
					data: { title: titleUpdate || 'Untitled', file: markdownFile },
					id: idUpdate,
				})
			)
			return
		}
		setOpen(true)
	}
	const handleChange = (e: any) => {
		setTitleState(e.target.value)
	}
	const getTitleName = () => {
	
		// Получаем содержимое редактора
		const markdownContent = editor.getValue()

		// Создаём объект File из содержимого Markdown
		const markdownFile = new File(
			[markdownContent],
			`${titleState || 'untitled'}.md`,
			{
				type: 'text/markdown',
			}
		)

		// Отправляем данные
		dispatch(
			fetchCreateMarkdownThunk({
				title: titleState || 'Untitled',
				file: markdownFile,
			})
		)
		setOpen(false)
	}
	const handleGetList = () => {
		dispatch(fetchMarkdownThunk())
		setModalList(true)
	}

	return (
		<div>
			<Button onClick={handleGetData}>
				{!isUpdate ? 'добавить' : 'обновить'}
			</Button>
			<Button onClick={handleGetList}>список документов</Button>
			<MarkdownEditorView
				stickyToolbar
				autofocus
				toaster={toaster}
				editor={editor}
			/>
			<Modal open={open} onClose={() => setOpen(false)}>
				<TextInput onChange={handleChange} placeholder={'Введите название'} />
				<Button onClick={getTitleName}>Отправить</Button>
			</Modal>
			{dataDocsSelectorLoadingLocal === false &&
				dataDocsSelectorErrorLocal === null && (
					<Modal open={modalList} onClose={() => setModalList(false)}>
						<input type='text' placeholder='Поиск...' />
						<ul>
							{dataDocsSelectorLocal.map((el: any) => {
								return (
									<li
										onClick={() => {
											
											dispatch(getMarkdownContentThunk(el.id))
											setIdUpdate(el.id)
											setTitleUpdate(el.title)
										}}
									>
										<h3>{el.title}</h3>
										<button
											onClick={event => {
												event.stopPropagation() // Предотвращает вызов обработчика клика у <li>
												dispatchThunk(fetchDeleteMarkdownThunkSlice(el.id))
											}}
										>
											delete
										</button>
									</li>
								)
							})}
						</ul>
					</Modal>
				)}
		</div>
	)
}

export default Editor
