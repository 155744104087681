import { createSelector } from '@reduxjs/toolkit'
import { IMarkState } from '../../types'

const selectBase = createSelector(
	(state: RootState) => state,
	state => state.markdownSlice
)

export const dataDocsSelector = createSelector(
	selectBase,
	(state: IMarkState) => state.markdown
)
export const dataDocsSelectorLoading = createSelector(
	selectBase,
	(state: IMarkState) => state.loading
)
export const dataDocsSelectorError = createSelector(
	selectBase,
	(state: IMarkState) => state.error
)
