import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IMarkdown } from '../../../../../shared/api/markdown/types'
import { IMarkState } from '../../types'
import { fetchMarkdownThunk } from './markdownThunk'

const initialState: IMarkState = {
	markdown: [
		{
			title: '',
			file: null,
		},
	],

	loading: false,
	error: null,
}
const markdownSlice = createSlice({
	name: 'markdown',
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(fetchMarkdownThunk.pending, state => {
				state.loading = true
				state.error = null
			})
			.addCase(
				fetchMarkdownThunk.fulfilled,
				(state, action: PayloadAction<IMarkdown[]>) => {
					state.markdown = action.payload
					state.loading = false
					state.error = null
				}
			)
			.addCase(fetchMarkdownThunk.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload ?? null
			}),
})

export default markdownSlice.reducer
