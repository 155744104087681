import { apiInstance } from '../base'
import { IMarkdown } from './types'

const BASE_URL = process.env.REACT_APP_BASE_URL
export const getMarkdownPages = async () => {
	return await apiInstance.get<IMarkdown[]>(`${BASE_URL}/api/documents`)
}
export const getMarkdownPageContentOne = async (
	id: number
): Promise<string> => {
	try {
		// Указываем, что ожидаем получить Blob (файл)
		const response = await apiInstance.get<Blob>(
			`${BASE_URL}/api/documents/${id}/content`,
			{
				responseType: 'blob', // Указание, что ожидаем файл
			}
		)

		const file = response // Теперь TypeScript понимает, что это Blob

		// Чтение содержимого файла с помощью FileReader
		const reader = new FileReader()

		return new Promise((resolve, reject) => {
			reader.onload = () => {
				if (typeof reader.result === 'string') {
					resolve(reader.result) // Возвращаем содержимое файла как строку
				} else {
					reject(new Error('File reading failed, result is not a string'))
				}
			}

			reader.onerror = () => {
				reject(new Error('FileReader error'))
			}

			reader.readAsText(file) // Читаем файл как текст
		})
	} catch (error) {
		console.error('Error fetching or reading file:', error)
		throw error
	}
}

export const addMarkdownPage = async (data: IMarkdown) => {
	return await apiInstance.post(`${BASE_URL}/api/documents`, data, {
		headers: {
			'Content-Type': 'multipart/form-data', // Указываем корректный заголовок
		},
	})
}

export const updateMarkdownPage = async (data: IMarkdown, id: number) => {
	return await apiInstance.put<IMarkdown>(
		`${BASE_URL}/api/documents/${id}`,
		data,
		{
			headers: {
				'Content-Type': 'multipart/form-data', // Указываем корректный заголовок
			},
		}
	)
}
export const deleteMarkdownPage = async (id: number) => {
	return await apiInstance.delete<any>(`${BASE_URL}/api/documents/${id}`)
}
