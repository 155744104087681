import { useMarkdownEditor } from '@gravity-ui/markdown-editor'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import {
	oneDataDocsSelector,
	oneDataDocsSelectorError,
	oneDataDocsSelectorLoading,
} from '../../entities/markdown/model/api/getOneContent/selectors'
import { Editor } from '../../widgets/Editor'

export const AppRouter = () => {
	const handleSubmit = (data: string) => {
		
		alert(`Submitted Markdown data:\n${data}`)
	}

	const oneDataDocsSelectorLoadingLocal = useSelector(
		oneDataDocsSelectorLoading
	)
	const oneDataDocsSelectorErrorLocal = useSelector(oneDataDocsSelectorError)
	const oneDataDocsSelectorLocal = useSelector(oneDataDocsSelector)

	const [initData, setInitData] = useState<string>('')
	const [isUpdate, setIsUpdate] = useState<boolean>(false)
	useEffect(() => {
		if (typeof oneDataDocsSelectorLocal === 'string') {
			setIsUpdate(true)
			setInitData(oneDataDocsSelectorLocal)
		} else {
			setInitData('')
		}
	}, [oneDataDocsSelectorLocal])

	const editor = useMarkdownEditor({
		initialToolbarVisible: true,
		initialMarkup: initData || '',
	})

	useEffect(() => {
		if (editor) {
			editor.clear()
			editor.append(initData)
		}
	}, [initData, editor])

	return (
		<Router>
			<Routes>
				<Route
					path='/'
					element={
						<Editor
							onSubmit={handleSubmit}
							editor={editor}
							isUpdate={isUpdate}
						/>
					}
				/>
			</Routes>
		</Router>
	)
}
