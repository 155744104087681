import { createAsyncThunk } from '@reduxjs/toolkit'

import { addMarkdownPage } from '../../../../../shared/api/markdown/markdown'
import { IMarkdown } from '../../../../../shared/api/markdown/types'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'

export const fetchCreateMarkdownThunk = createAsyncThunk<
	IMarkdown, // Return type of the fulfilled action
	IMarkdown, // Argument type (payload creator's first parameter)
	{ rejectValue: RejectedDataType } // Custom rejection value type
>('markdown/fetchCreateMarkdown', async (data: IMarkdown, thunkAPI) => {
	try {
		const response = await addMarkdownPage(data)
		return response
	} catch (err: unknown) {
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
