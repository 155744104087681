import { configureStore } from '@reduxjs/toolkit'
import markdownSlice from '../entities/markdown/model/api/get/markdownSlice'
import markdownSliceContentOne from '../entities/markdown/model/api/getOneContent/markdownSlice'
import markdownCreateSlice from '../entities/markdown/model/api/post/markdownSlice'
import markdownUpdateSlice from '../entities/markdown/model/api/put/markdownSlice'

const store = configureStore({
	reducer: {
		markdownSlice,
		markdownCreateSlice,
		markdownSliceContentOne,
		markdownUpdateSlice,
	},
})
export default store
