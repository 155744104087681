import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IMarkdown } from '../../../../../shared/api/markdown/types'
import { IMarkStateOne } from '../../types'
import { fetchCreateMarkdownThunk } from './markdownThunk'

const initialState: IMarkStateOne = {
	markdown: {
		title: '',
		file: null,
	},
	loading: false,
	error: null,
}
const markdownCreateSlice = createSlice({
	name: 'markdown',
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(fetchCreateMarkdownThunk.pending, state => {
				state.loading = true
				state.error = null
			})
			.addCase(
				fetchCreateMarkdownThunk.fulfilled,
				(state, action: PayloadAction<IMarkdown>) => {
					state.markdown = action.payload
					state.loading = false
					state.error = null
				}
			)
			.addCase(fetchCreateMarkdownThunk.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload ?? null
			}),
})

export default markdownCreateSlice.reducer
