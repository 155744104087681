import { createAsyncThunk } from '@reduxjs/toolkit'
import { updateMarkdownPage } from '../../../../../shared/api/markdown/markdown'
import { IMarkdown } from '../../../../../shared/api/markdown/types'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'

// Обновленный createAsyncThunk для запроса с двумя параметрами
export const fetchPutMarkdownThunk = createAsyncThunk<
	IMarkdown, // Return type of the fulfilled action
	{ data: IMarkdown; id: number }, // Аргументы, которые передаются в thunk (объект с data и id)
	{ rejectValue: RejectedDataType } // Тип ошибки в случае отклонения
>('markdown/fetchPutMarkdown', async ({ data, id }, thunkAPI) => {
	try {
		// Отправка запроса на обновление страницы
		const response = await updateMarkdownPage(data, id) // передаем data и id в updateMarkdownPage
		return response
	} catch (err: unknown) {
		// Обработка ошибки
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
