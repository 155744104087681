import { createAsyncThunk } from '@reduxjs/toolkit'

import { getMarkdownPages } from '../../../../../shared/api/markdown/markdown'
import { IMarkdown } from '../../../../../shared/api/markdown/types'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'

export const fetchMarkdownThunk = createAsyncThunk<
	IMarkdown[],
	void,
	{ rejectValue: RejectedDataType }
>('markdown/fetchMarkdown', async (_, thunkAPI) => {
	try {
		const response = await getMarkdownPages()
		return response
	} catch (err: unknown) {
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
