import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchDeleteMarkdownThunk } from '../../delete/markdownThunk'
import { fetchMarkdownThunk } from '../../get/markdownThunk'

export const fetchDeleteMarkdownThunkSlice = createAsyncThunk<
	void, // Нет возвращаемого значения
	number, // ID, который мы передаем в deleteMarkdownPage
	{ rejectValue: string } // Тип отклонённого значения
>('markdown/deleteMarkdown', async (id, thunkAPI) => {
	try {
		await thunkAPI.dispatch(fetchDeleteMarkdownThunk(id)).unwrap()
		await thunkAPI.dispatch(fetchMarkdownThunk()).unwrap()
	} catch (error) {
		return thunkAPI.rejectWithValue('Ошибка при удалении страницы')
	}
})
