import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteMarkdownPage } from '../../../../../shared/api/markdown/markdown'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'

// Обновленный createAsyncThunk для запроса с двумя параметрами
export const fetchDeleteMarkdownThunk = createAsyncThunk<
	any,
	any,
	{ rejectValue: RejectedDataType } // Тип ошибки в случае отклонения
>('markdown/fetchDeleteMarkdown', async (id, thunkAPI) => {
	try {
		// Отправка запроса на обновление страницы
		const response = await deleteMarkdownPage(id) // передаем data и id в updateMarkdownPage
		return response
	} catch (err: unknown) {
		// Обработка ошибки
		const knownError = err as ErrorType
		return thunkAPI.rejectWithValue({
			messageError: knownError.message,
			status: knownError.response?.status,
		})
	}
})
