import axios from 'axios'

export const API_URL = process.env.REACT_APP_SERVER

class ApiInstance {
	private axios = axios.create({
		baseURL: API_URL,
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			Accept: 'application/json', // Добавляем заголовок Accept для поддержки CORS
			// Другие заголовки по необходимости
		},
		withCredentials: true,
	})

	// Метод GET
	async get<T>(endpoint: string, options = {}): Promise<T> {
		const response = await this.axios.get<T>(endpoint, options)
		return response.data
	}

	// Метод POST
	async post<T>(endpoint: string, data: T, options = {}): Promise<T> {
		const response = await this.axios.post<T>(endpoint, data, options)
		return response.data
	}
	async postJSON<TRequest, TResponse>(
		endpoint: string,
		data: TRequest | string,
		options = {}
	): Promise<TResponse> {
		const response = await this.axios.post<TResponse>(endpoint, data, options);
		return response.data;
	}
	
	

	// Метод PUT
	async put<T>(endpoint: string, data: T, options = {}): Promise<T> {
		const response = await this.axios.put<T>(endpoint, data, options)
		return response.data
	}

	// Метод PATCH
	async patch<T>(endpoint: string, data: Partial<T>, options = {}): Promise<T> {
		const response = await this.axios.patch<T>(endpoint, data, options)
		return response.data
	}

	// Метод DELETE
	async delete<T>(endpoint: string, options = {}): Promise<T> {
		const response = await this.axios.delete<T>(endpoint, options)
		return response.data
	}
}

export const apiInstance = new ApiInstance()
