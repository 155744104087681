import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMarkdownPageContentOne } from '../../../../../shared/api/markdown/markdown'
import {
	ErrorType,
	RejectedDataType,
} from '../../../../../shared/types/errorTypes'

export const getMarkdownContentThunk = createAsyncThunk<
	string, // Return type matches File, which is the actual data type returned by the API
	number, // Argument type (id)
	{ rejectValue: RejectedDataType } // Additional thunk API options
>('markdown/fetchContent', async (id, thunkAPI) => {
	try {
		const response = await getMarkdownPageContentOne(id) // Fetch content by ID
		return response // Axios returns a `File` inside response.data
	} catch (err: unknown) {
		const knownError = err as ErrorType // Cast error to a known type
		return thunkAPI.rejectWithValue({
			messageError: knownError.message ?? 'Unknown error occurred',
		})
	}
})
