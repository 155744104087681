import { ThemeProvider } from '@gravity-ui/uikit'
import '@gravity-ui/uikit/styles/styles.css'
import { Providers } from './app/providers'
import { AppRouter } from './app/routers'

// ...
function App() {
	return (
		<ThemeProvider theme='light'>
			<Providers>
				<AppRouter />
			</Providers>
		</ThemeProvider>
	)
}

export default App
